import React from "react";
import "../styles/components.styles/projectInfo.css";
import fondo from "../assets/img/fondo.png";

const ProjectInfo = () => {
  return (
    <section id="acerca-de" className="project-info">
      <div
        className="project-info-container"
        style={{ backgroundImage: `url(${fondo})` }}
      >
        <h2>Villa OASIS</h2>
        <p className="project-description">
          Vivir en un desierto cuesta mucho encontrar donde saciar nuestra sed,
          y calmar la ansiedad de la rutina. Solo en el oasis, en medio del
          desierto, encontramos ese lugar idóneo para saciar la sed y
          encontrarnos a nosotros mismos. Pero también, reencontrarnos con la
          esencia de la humanidad “La Familia”. Allí sentimos el refugio y
          descanso espiritual alineando nuestra alma, y también para divertirnos
          conectándonos en familia. Salmos 23: 1-2
        </p>

        <h3>Nuestra Visión</h3>
        <p className="project-vision">
          Nuestro visión está enfocada al buen servicio, armonía familiar y
          brindar una atmósfera acogedora a la paz espiritual. Donde además de
          pasar un buen tiempo y hacer turismo, puedan recibir temporada de
          calidad con los que amas. .
        </p>
      </div>
    </section>
  );
};

export default ProjectInfo;
