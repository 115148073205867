import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Efecto de desenfoque
import "../styles/components.styles/rulesSection.css"; // Asegúrate de enlazar correctamente el archivo CSS
import icon1 from "../assets/img/icon1.png";
import icon2 from "../assets/img/icon2.png";
import icon3 from "../assets/img/icon3.png";
import icon4 from "../assets/img/icon4.png";
import icon5 from "../assets/img/icon5.png";
import icon6 from "../assets/img/icon6.png";
import icon7 from "../assets/img/icon7.png";
import icon8 from "../assets/img/icon8.png";
import icon9 from "../assets/img/icon9.png";

const rules = [
  {
    img: icon1,
    text: "Energía eléctrica no incluida. Debe recargar. Nosotros le asistimos en el proceso.",
    alt: "Energía eléctrica",
  },
  {
    img: icon2,
    text: "No fiestas, Ni Eventos.",
    alt: "No fiestas ni eventos",
  },
  {
    img: icon3,
    text: "No fumar.",
    alt: "No fumar",
  },
  {
    img: icon4,
    text: "No animales.",
    alt: "No animales",
  },
  {
    img: icon5,
    text: "No dejar encendido los AC si no está en la residencia.",
    alt: "No dejar encendido el AC",
  },
  {
    img: icon6,
    text: "No dejar las toallas en el piso.",
    alt: "No dejar toallas en el piso",
  },
  {
    img: icon7,
    text: "Llevar la basura al zafacón (en la entrada del residencial o alrededor de la piscina).",
    alt: "Llevar la basura al zafacón",
  },
  {
    img: icon8,
    text: "Auto chequeo con cerradura electrónica.",
    alt: "Auto chequeo con cerradura",
  },
  {
    img: icon9,
    text: "Invitados recargar su electricidad cada 5 KWH.",
    alt: "Recargar electricidad",
  },
];

const RuleSection = () => {
  return (
    <section id="reglas" className="ruleSection">
      <h2>REGLAS DE LA CASA</h2>
      <p className="subtitle">Disfrute de su estancia</p>

      <div className="rules-grid">
        {rules.map((rule, index) => (
          <div className="rule" key={index}>
            <LazyLoadImage
              src={rule.img}
              alt={rule.alt}
              effect="blur" // Efecto de desenfoque al cargar
            />
            <p>{rule.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default RuleSection;
