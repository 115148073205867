import React from "react";
import HeroSection from "../components/heroSection";
import RuleSection from "../components/rulesSection";
import VideoSection from "../components/videoSection";
import ContactForm from "../components/contactForm";
import ProjectInfo from "../components/projectInfo";
//import ServicesSection from "../components/ServicesSection";
//import Testimonials from "../components/Testimonials";
import "../styles/home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home() {
  return (
    <div className="home">
      <HeroSection />
      <ProjectInfo />
      <VideoSection />
      <RuleSection />
      <ContactForm />
    </div>
  );
}

export default Home;
