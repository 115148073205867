import React from "react";
import Slider from "react-slick";
import "../styles/components.styles/heroSection.css"; // Asegúrate de agregar los estilos necesarios aquí
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "@justinribeiro/lite-youtube";

// Ejemplo de imágenes
import slide1 from "../assets/img/2.png";
import slide2 from "../assets/img/foto4.jpg";
import slide4 from "../assets/img/098.jpg";
import slide5 from "../assets/img/434.jpg";
import slide6 from "../assets/img/454.jpg";
import slide7 from "../assets/img/674.jpg";
import slide8 from "../assets/img/678.jpg";
import overlayImage from "../assets/img/logolargo.png"; // Imagen sobrepuesta

function heroSection() {
  // Configuración del slider
  const settings = {
    dots: false, // Para mostrar los indicadores de deslizamiento
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true, // Para transiciones suaves entre imágenes
    cssEase: "linear",
    arrows: false, // Oculta los botones de navegación
  };

  return (
    <section id="TM" className="hero-section">
      {/* Slider de fondo */}
      <Slider {...settings} className="slider-background">
        <div>
          <LazyLoadImage
            src={slide1}
            alt="Slide 1"
            effect="blur"
            className="slider-image"
          />
        </div>
        <div>
          <LazyLoadImage
            src={slide2}
            alt="Slide 2"
            effect="blur"
            className="slider-image"
          />
        </div>
        <div>
          <LazyLoadImage
            src={slide4}
            alt="Slide 4"
            effect="blur"
            className="slider-image"
          />
        </div>
        <div>
          <LazyLoadImage
            src={slide5}
            alt="Slide 5"
            effect="blur"
            className="slider-image"
          />
        </div>
        <div>
          <LazyLoadImage
            src={slide6}
            alt="Slide 6"
            effect="blur"
            className="slider-image"
          />
        </div>
        <div>
          <LazyLoadImage
            src={slide7}
            alt="Slide 7"
            effect="blur"
            className="slider-image"
          />
        </div>
        <div>
          <LazyLoadImage
            src={slide8}
            alt="Slide 8"
            effect="blur"
            className="slider-image"
          />
        </div>
      </Slider>

      {/* Imagen sobrepuesta */}
      <div className="overlay-image">
        <LazyLoadImage src={overlayImage} alt="overlay" effect="blur" />
      </div>
    </section>
  );
}

export default heroSection;
