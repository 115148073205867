import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/components.styles/header.css";
import logo1 from "../assets/img/Logo1.png";
import { Link as ScrollLink } from "react-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Estado para el menú de hamburguesa

  // Función para manejar el estado del menú de hamburguesa
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        {/* Logo */}
        <div className="logo">
          <Link to="/">
            <LazyLoadImage src={logo1} alt="Logo" effect="blur" />
          </Link>
        </div>
        {/* Botón de menú hamburguesa para móviles */}
        <div className="hamburger" onClick={toggleMobileMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        {/* Navigation */}
        <nav className={`navbar ${isMobileMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <ScrollLink to="TM" smooth={true} duration={500}>
                TM
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="acerca-de" smooth={true} duration={500}>
                Acerca de
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="galeria" smooth={true} duration={500}>
                Galería
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="reglas" smooth={true} duration={500}>
                Reglas
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="reserva" smooth={true} duration={500}>
                Contacto
              </ScrollLink>
            </li>
          </ul>
        </nav>
        {/* Social media */}
        <div className="social-media-buttons">
          <a
            href="https://www.facebook.com/TMHomeAndServices"
            target="_blank"
            rel="noopener noreferrer"
            className="facebook"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href="https://www.instagram.com/tmhomeandservices/"
            target="_blank"
            rel="noopener noreferrer"
            className="instagram"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
