import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../styles/components.styles/contactForm.css";
import fondo3 from "../assets/img/fondo3.png";

const BookingForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    phone: "",
    guests: 1,
    message: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Actualiza los campos ocultos con las fechas antes de enviar el formulario
    form.current.startDate.value = startDate
      ? startDate.format("DD/MM/YYYY")
      : "";
    form.current.endDate.value = endDate ? endDate.format("DD/MM/YYYY") : "";

    emailjs
      .sendForm(
        "service_lc0enqm",
        "template_168o65l",
        form.current,
        "UScO7Xk5CfKj6y8Mn"
      )
      .then(
        () => {
          alert(
            "Gracias por tu reserva. Nos pondremos en contacto contigo pronto."
          );
          setFormData({
            user_name: "",
            user_email: "",
            phone: "",
            guests: 1,
            message: "",
          });
          setStartDate(null);
          setEndDate(null);
        },
        (error) => {
          console.error("Error al enviar el formulario:", error);
          alert(
            "Hubo un error al enviar tu reserva. Por favor, inténtalo de nuevo."
          );
        }
      );
  };

  return (
    <section
      id="reserva"
      className="booking-form-section"
      style={{ backgroundImage: `url(${fondo3})` }}
    >
      <div className="booking-form-container">
        <h2>Reserva tu Estadia</h2>
        <p>Planifica tu estadía en nuestra villa con anticipación.</p>
        <form ref={form} onSubmit={sendEmail} className="booking-form">
          <input
            type="text"
            name="user_name"
            value={formData.user_name}
            onChange={handleChange}
            placeholder="Nombre Completo"
            required
          />
          <input
            type="email"
            name="user_email"
            value={formData.user_email}
            onChange={handleChange}
            placeholder="Correo Electrónico"
            required
          />
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Teléfono"
            required
          />
          <label>Selecciona tu rango de fechas:</label>
          <DateRangePicker
            startDate={startDate}
            startDateId="start-date"
            endDate={endDate}
            endDateId="end-date"
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            displayFormat="DD/MM/YYYY"
            numberOfMonths={1}
          />
          <input
            type="hidden"
            name="startDate"
            value={startDate ? startDate.format("DD/MM/YYYY") : ""}
          />
          <input
            type="hidden"
            name="endDate"
            value={endDate ? endDate.format("DD/MM/YYYY") : ""}
          />
          <div className="numero_huespedes">
            <label className="numero_huespedes">Número de Huéspedes:</label>
          </div>

          <input
            type="number"
            name="guests"
            min="1"
            max="20"
            value={formData.guests}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Comentarios adicionales (opcional)"
          />
          <button type="submit">Reservar Ahora</button>
        </form>
      </div>
    </section>
  );
};

export default BookingForm;
