import React from "react";
import "../styles/components.styles/footer.css"; // Asegúrate de importar el archivo CSS

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; 2024 TM Properties and Rentals</p>
    </footer>
  );
}

export default Footer;
