import React, { useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../styles/components.styles/videoSection.css"; // Archivo CSS
import "@justinribeiro/lite-youtube";

import img1 from "../assets/img/2.png";
import img2 from "../assets/img/098.jpg";
import img3 from "../assets/img/322.jpg";
import img4 from "../assets/img/434.jpg";
import img5 from "../assets/img/454.jpg";
// import img6 from "../assets/img/674.jpg";
import img7 from "../assets/img/678.jpg";

const videos = [
  {
    type: "video",
    videoId: "hYQ1ckzBuQA",
    alt: "video 1.1",
  },
  {
    type: "image",
    url: img1,
    alt: "Imagen 1",
  },
  {
    type: "video",
    videoId: "IClZ5GOSpvs",
    alt: "Video 1",
  },
  {
    type: "image",
    url: img2,
    alt: "Imagen 2",
  },
  {
    type: "video",
    videoId: "XFtAjj3qqKU",
    alt: "Video 1",
  },
  {
    type: "image",
    url: img3,
    alt: "Imagen 3",
  },
  {
    type: "video",
    videoId: "Wb6G3ezfeHo",
    alt: "Video 1",
  },
  {
    type: "image",
    url: img4,
    alt: "Imagen 4",
  },
  {
    type: "video",
    videoId: "TQ_FAaCDf3Y",
    alt: "Video 1",
  },
  {
    type: "image",
    url: img5,
    alt: "Imagen 5",
  },
  {
    type: "image",
    url: img7,
    alt: "Imagen 7",
  },
];

const VideoSection = () => {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGridView, setIsGridView] = useState(false); // Estado para alternar entre vistas
  const cardContainerRef = useRef(null);

  const toggleView = (view) => {
    setIsGridView(view === "grid");
  };

  const scrollLeft = () => {
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <section id="galeria" className="videoSection">
      <div className="title">
        <h2>GALERIA</h2>
        <p className="subtitle">Disfrute de su estancia</p>
      </div>

      <div className="view-buttons">
        <button
          onClick={() => toggleView("slider")}
          className={!isGridView ? "active" : ""}
        >
          <i className="bi bi-view-list"></i>
        </button>
        <button
          onClick={() => toggleView("grid")}
          className={isGridView ? "active" : ""}
        >
          <i className="bi bi-grid-3x3-gap"></i>
        </button>
      </div>

      <div
        className={`card-container ${isGridView ? "grid-view" : "slider-view"}`}
        ref={!isGridView ? cardContainerRef : null}
      >
        {videos.map((item, index) => (
          <div key={index} className="card" onClick={() => openModal(item)}>
            {item.type === "video" ? (
              <div className="video-container">
                <lite-youtube
                  videoid={item.videoId}
                  params="autoplay=0&mute=0"
                  style={{ width: "100%", height: "100%" }}
                  title={item.alt}
                ></lite-youtube>
              </div>
            ) : (
              <LazyLoadImage
                src={item.url}
                alt={item.alt}
                className="image-slide"
                effect="blur"
              />
            )}
          </div>
        ))}
      </div>

      {!isGridView && (
        <>
          <button className="scroll-button left" onClick={scrollLeft}>
            &#10094;
          </button>
          <button className="scroll-button right" onClick={scrollRight}>
            &#10095;
          </button>
        </>
      )}

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>
              X
            </button>
            {modalContent?.type === "video" ? (
              <lite-youtube
                videoid={modalContent.videoId}
                params="autoplay=1&mute=0"
                style={{ width: "100%", height: "100%" }}
                title={modalContent.alt}
              ></lite-youtube>
            ) : (
              <LazyLoadImage
                src={modalContent.url}
                alt={modalContent.alt}
                className="modal-image"
                effect="blur"
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default VideoSection;
